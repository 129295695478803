/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { pinyin } from 'pinyin-pro';
export interface FirstLetter {
  name: string;
  bgColor: string;
}
export default class Utils {
  colorList = [
    '#00ffff',
    '#00ffff',
    '#7fffd4',
    '#ffe4c4',
    '#ff7f50',
    '#ffd700'
  ];
  /** 获取url参数 */
  getQueryVariable(variable: string): boolean | string {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  }

  /** 判断是否为空 */
  isEmpty(value: unknown): boolean {
    if (typeof value === 'undefined' || value === null || value === '') {
      return true;
    } else {
      return false;
    }
  }

  /** 判断数组是否相等 */
  isEqualsArray(array1: Array<any>, array2: Array<any>) {
    if (!array1 || !array2) return false;
    if (array1.length !== array2.length) return false;

    for (let i = 0, l = array1.length; i < l; i++) {
      if (array1[i] instanceof Array && array2[i] instanceof Array) {
        if (!array1[i].equals(array2[i])) return false;
      } else if (array1[i] != array2[i]) {
        return false;
      }
    }
    return true;
  }

  /** 去除html标签 */
  removeHtmlLabel(str: string) {
    return str.replace(/<[^<>]+>/g, '').replace(/&nbsp;/gi, '');
  }

  /** 返回字符串第一个字，如果是英文则转成大写 */
  getFirstLetter(str: string): FirstLetter {
    const firstLetter = str.substr(0, 1);
    const regChs = new RegExp('[\u4E00-\u9FA5]');
    const regEn = new RegExp('[A-Za-z]');
    if (regChs.test(firstLetter) || regEn.test(firstLetter)) {
      const capital = regChs.test(firstLetter)
        ? pinyin(firstLetter).substr(0, 1).toUpperCase()
        : firstLetter.toUpperCase();

      return {
        name: firstLetter,
        bgColor: this.getLetterColor(capital)
      };
    } else {
      return {
        name: '-',
        bgColor: this.colorList[5]
      };
    }
  }

  getLetterColor(str: string): string {
    const code = str.charCodeAt(0);
    let color = '';
    if (code >= 65 && code < 70) {
      color = this.colorList[0];
    } else if (code >= 70 && code < 75) {
      color = this.colorList[1];
    } else if (code >= 75 && code < 80) {
      color = this.colorList[2];
    } else if (code >= 80 && code < 85) {
      color = this.colorList[3];
    } else {
      color = this.colorList[4];
    }
    return color;
  }

  /** 删除url参数 */
  // removeURLParameter(url: , parameter) {
  //   var urlparts = url.split('?');
  //   if(urlparts.length >= 2) {
  //     //参数名前缀
  //     var prefix = encodeURIComponent(parameter) + '=';
  //     var pars = urlparts[1].split(/[&;]/g);

  //     //循环查找匹配参数
  //     for(var i = pars.length; i-- > 0;) {
  //       if(pars[i].lastIndexOf(prefix, 0) !== -1) {
  //         //存在则删除
  //         pars.splice(i, 1);
  //       }
  //     }

  //     return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  //   }
  //   return url;
  // }
}
