export interface TokenInfo {
  token: string;
  invalidTime: number;
  invalidTimeLength: number;
  role: number;
}
export interface UserInfo {
  userId: number;
  username: string;
  account: string;
}

export default class RuntimeUser {
  constructor() {
    // console.log('[AtsApi Constructor]');
  }

  /** 清空所有用户相关信息 */
  clearAllUserInfo(): void {
    this.clearCmsToken();
    this.clearTokenInfo();
    this.clearUserInfo();
  }

  /** 检查token是否过期,true为过期，false为未过期 */
  checkTokenTime(): boolean {
    const tokenInfo: TokenInfo = this.getTokenInfo();
    if (tokenInfo.token === undefined) {
      return true;
    }
    const nowTime = new Date().getTime();
    return nowTime > tokenInfo.invalidTime;
  }

  /** 刷新过期时间 */
  refreshTokenTime(): void {
    const tokenInfo: TokenInfo = this.getTokenInfo();
    const invalidTime = new Date().getTime() + tokenInfo.invalidTimeLength;
    this.setTokenInfo(
      tokenInfo.token,
      invalidTime,
      tokenInfo.invalidTimeLength,
      tokenInfo.role
    );
  }

  /** 登陆/换token
   * @param token 获取的用户token
   * @param time 获取的过期时间毫秒
   */
  // changeToken(token: string, time: number): void {
  //   const role = this.getTokenInfo().role;
  //   // 获取失效时间存入localstorage
  //   const invalidTime = new Date().getTime() + time;
  //   this.setTokenInfo(token, invalidTime, time, role);
  // }

  /** 设置token */
  setTokenInfo(
    token: string,
    invalidTime: number,
    invalidTimeLength: number,
    role: number
  ): void {
    localStorage.setItem(
      'tokenInfo',
      JSON.stringify({
        token,
        invalidTime,
        invalidTimeLength,
        role
      })
    );
  }

  /** 清空token */
  clearTokenInfo(): void {
    localStorage.removeItem('tokenInfo');
  }

  /** 获取token */
  getTokenInfo(): TokenInfo {
    const tokenInfo: TokenInfo = localStorage.getItem('tokenInfo')
      ? JSON.parse(localStorage.getItem('tokenInfo') as string)
      : '{}';
    return tokenInfo;
  }

  /** 设置cmsToken */
  setCmsToken(cmsToken: string): void {
    localStorage.setItem('cmsToken', cmsToken);
  }

  /** 清空cmsToken */
  clearCmsToken(): void {
    localStorage.removeItem('cmsToken');
  }

  /** 获取cmsToken */
  getCmsToken(): string {
    const cmsToken = localStorage.getItem('cmsToken')
      ? (localStorage.getItem('cmsToken') as string)
      : '';
    return cmsToken;
  }

  /** 设置用户信息 */
  setUserInfo(userInfo: UserInfo): void {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  }

  /** 清空用户信息 */
  clearUserInfo(): void {
    localStorage.removeItem('userInfo');
  }

  /** 获取用户信息 */
  getUserInfo(): UserInfo {
    const userInfo: UserInfo = localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo') as string)
      : '{}';
    return userInfo;
  }

  /** 写入时间差 */
  setTimeDeffer(time: number): void {
    localStorage.setItem('timeDeffer', String(time));
  }

  /** 获取服务器时间 */
  getServiceTime(): number {
    const timeDeffer = localStorage.getItem('timeDeffer')
      ? Number(localStorage.getItem('timeDeffer'))
      : 0;
    return new Date().getTime() + timeDeffer;
  }
}
