/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios';
import type { Result } from '../types/axios';
import type { CreateAxiosOptions } from './axiosTransform';
import axios from 'axios';
import { cloneDeep } from 'lodash-es';
import { ContentTypeEnum } from '../../enum/httpEnum';
import RuntimeUser from '@/services/user';
import { env } from '@/services/env';
import router from '@/router';
import qs from 'qs';
import Tips from '@/utils/tips';
import { progress } from '@/components/create-task-components/create-task-data';

export * from './axiosTransform';

export interface ApiError {
  type: 'httpError' | 'serverError';
  code: string | number | null;
  msg: string | null;
}

/**
 * @description:  axios module
 */
export class VAxios {
  private axiosInstance: AxiosInstance;
  private readonly options: CreateAxiosOptions | undefined;
  private tips;

  public loadingPercent: number;

  constructor(options?: CreateAxiosOptions) {
    this.options = options;
    this.axiosInstance = axios.create(options);
    this.tips = new Tips();
    this.loadingPercent = 0;
  }

  get<T = any>(
    config: { url: string; params?: any; data?: any },
    serverSetting?: {
      tokenRequire?: boolean;
    }
  ): Promise<T> {
    return this.request({ ...config, method: 'GET' }, serverSetting);
  }

  post<T = any>(
    config: { url: string; params?: any; data?: any },
    serverSetting?: {
      tokenRequire?: boolean;
    },
    type?: 'file'
  ): Promise<T> {
    return this.request({ ...config, method: 'POST' }, serverSetting, type);
  }

  put<T = any>(
    config: { url: string; params?: any; data?: any },
    serverSetting?: {
      tokenRequire?: boolean;
    }
  ): Promise<T> {
    return this.request({ ...config, method: 'PUT' }, serverSetting);
  }

  delete<T = any>(
    config: {
      url: string;
      params?: any;
      data?: any;
    },
    serverSetting?: {
      tokenRequire?: boolean;
    }
  ): Promise<T> {
    return this.request({ ...config, method: 'DELETE' }, serverSetting);
  }

  /** request拦截器，拦截业务层错误以及网络层错误 */
  request<T = any>(
    config: AxiosRequestConfig,
    serverSetting?: {
      tokenRequire?: boolean;
    },
    type?: 'file'
  ): Promise<T> {
    let tokenRequire = false;
    // 是否去除token
    if (serverSetting) {
      tokenRequire = serverSetting.tokenRequire === false;
    }
    const runtimeUser = new RuntimeUser();

    const formData = new window.FormData();
    // 处理config.data为文件的数据
    if (type === 'file') {
      Object.keys(config.data).forEach(key => {
        config.data[key].forEach((element: string | Blob) => {
          formData.append(key, element);
        });
      });
    }

    // 属性深度克隆并处理token
    const conf = {
      ...cloneDeep(config),
      url: env.baseUrl + env.matUrl + config.url, // url拼接
      data: type === 'file' ? formData : qs.stringify(config.data),
      withCredentials: false, // 跨域
      headers: {
        'Content-type': ContentTypeEnum.FORM_URLENCODED,
        ignoreCancelToken: true,
        token: !tokenRequire ? runtimeUser.getTokenInfo().token : '',
        cmsToken: runtimeUser.getCmsToken() ? runtimeUser.getCmsToken() : ''
      },
      onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
        const loadingPercent =
          ((progressEvent.loaded / progressEvent.total) * 100) | 0; //上传进度百分比
        progress.value = loadingPercent;
      }
    };

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .request<any, AxiosResponse<Result>>(conf)
        .then((res: AxiosResponse<Result>) => {
          // 获取当前服务器时间差值
          runtimeUser.setTimeDeffer(
            Number(res.data.timestamp) - new Date().getTime()
          );
          // 处理业务系统返回的code
          if (res.data.code === 0 && !tokenRequire) {
            // 如果接口需要token的情况下，刷新token过期时间
            runtimeUser.refreshTokenTime();
            resolve(res.data.data);
          } else {
            const logoutList = [410, 411];
            // 以上code直接重定向到登陆页
            if (logoutList.indexOf(res.data.code) !== -1) {
              runtimeUser.clearAllUserInfo();
              router.replace({
                path: '/login',
                query: {
                  ...router.currentRoute.value.query,
                  route: router.currentRoute.value.path
                }
              });
            }
            const error: ApiError = {
              type: 'serverError',
              code: res.data.code,
              msg: res.data.msg
            };
            reject(error);
          }
        })
        .catch((e: { response: Promise<T> }) => {
          const error: ApiError = {
            type: 'httpError',
            code: (e.response as any).status,
            msg: `[http error]:${(e.response as any).status}`
          };
          reject(error);
        });
    });
  }
}
