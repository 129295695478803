/* eslint-disable @typescript-eslint/no-explicit-any */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import user from '@/services/user';
import { Role } from '@/enum/httpEnum';
const users = new user();

export const routes: Array<RouteRecordRaw | any> = [
  {
    path: '/home',
    name: 'admin',
    component: () => import('@/views/home.vue'),
    meta: { hidden: true, title: '首页' },
    props: {
      visitEnable: [
        Role.General,
        Role.Examine,
        Role.Professional,
        Role.SuperAdmin
      ]
    }
  },
  {
    path: '/task/detail/manage/:id',
    name: 'task-detail-manage',
    component: () => import('@/views/task-detail.vue'),
    meta: { hidden: true, title: '任务详情' },
    props: {
      visitEnable: [
        Role.General,
        Role.Examine,
        Role.Professional,
        Role.SuperAdmin
      ]
    }
  },
  {
    path: '/task/detail/view/:id',
    name: 'task-detail-view',
    component: () => import('@/views/task-detail.vue'),
    meta: { hidden: true, title: '任务详情' },
    props: {
      visitEnable: [
        Role.General,
        Role.Examine,
        Role.Professional,
        Role.SuperAdmin
      ]
    }
  },
  {
    path: '/tag/:id',
    name: 'tagging',
    component: () => import('@/views/tagging.vue'),
    meta: { hidden: true, title: '属性标注' },
    props: {
      visitEnable: [
        Role.General,
        Role.Examine,
        Role.Professional,
        Role.SuperAdmin
      ]
    }
  },
  {
    path: '/tag/view/:id',
    name: 'tag-view',
    component: () => import('@/views/tag-view.vue'),
    meta: { hidden: true, title: '属性标注查看' },
    props: {
      visitEnable: [
        Role.General,
        Role.Examine,
        Role.Professional,
        Role.SuperAdmin
      ]
    }
  },
  {
    path: '/preview',
    name: 'preview',
    component: () => import('@/views/preview-tagging.vue'),
    meta: { hidden: true, title: '预览' },
    props: {
      visitEnable: [
        Role.General,
        Role.Examine,
        Role.Professional,
        Role.SuperAdmin
      ]
    }
  },
  {
    path: '/tag/err/:id',
    name: 'tag-err',
    component: () => import('@/views/tag-err.vue'),
    meta: { hidden: true, title: '错误集' },
    props: {
      visitEnable: [
        Role.General,
        Role.Examine,
        Role.Professional,
        Role.SuperAdmin
      ]
    }
  },
  {
    path: '/tag/result/:id/:listNo',
    name: 'tag-result',
    component: () => import('@/views/tag-result.vue'),
    meta: { hidden: true, title: '结果集' },
    props: {
      visitEnable: [
        Role.General,
        Role.Examine,
        Role.Professional,
        Role.SuperAdmin
      ]
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
    meta: { hidden: true, title: '登陆' },
    props: {
      visitEnable: [
        Role.General,
        Role.Examine,
        Role.Professional,
        Role.SuperAdmin,
        Role.Visitor
      ]
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue'),
    meta: { hidden: true, title: '404' },
    props: {
      visitEnable: [
        Role.General,
        Role.Examine,
        Role.Professional,
        Role.SuperAdmin,
        Role.Visitor
      ]
    }
  }
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  let role = users.getTokenInfo().role;
  // 设置页面标题
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (role === undefined) {
    role = Role.Visitor;
  }
  // 不存在的路由跳转404;
  if (to.matched.length === 0 && to.path !== '/') {
    next('/404');
  } else if (to.path === '/') {
    next('/home');
  } else {
    let visitEnable: Array<Role> = [];
    routes.forEach(element => {
      if (element.path === to.matched[0].path) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        visitEnable = (element as any).props.visitEnable;
      }
    });
    // 当前路由权限隔离
    // 非访客
    if (visitEnable.indexOf(role) !== -1) {
      if (to.path === '/login' && role !== Role.Visitor) {
        next('/home');
      } else {
        next();
      }
    } else {
      next({
        path: '/login',
        query: {
          ...to.query,
          route: to.path
        }
      });
    }
  }
});

export default router;
