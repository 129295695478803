/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Utils from '@/utils/utils';
import { Ref, ref } from 'vue';

/**
 * 数据类型定义
 */
export interface DialogInfo {
  taskId: number; // 任务id
  taskName: string; // 任务名
  authorityListStr: { userId: number; authority: number; account: string }[]; // 权限列表
  taskMode: number; // 任务类型，目前只有属性标注
  formJson: string; // 题目项
  picListStr: {
    url: string;
    picWidth: number;
    picHeight: number;
    fileName: string;
  }[]; // 需要上传图片的列表
  jobFileCount: number; // 子任务文件数
  picQuality: number; // 文件质量
  projectGroup: { value: string; label: string }[]; // 已有分组
}

export const progress = ref(-1);

/**
 * 创建任务的参数
 * @param taskName 任务名称
 * @param authorityListStr 权限列表(userId: 1,authority: 1， account: '') 其中account接口不传
 */
export const dialogInfo: Ref<DialogInfo> = ref({
  taskId: 0,
  taskName: '',
  authorityListStr: [],
  taskMode: 0,
  formJson: '',
  picListStr: [],
  jobFileCount: 1,
  picQuality: 1,
  projectGroup: []
});

/**
 * 编辑任务的参数
 * @param taskName 任务名称
 * @param authorityListStr 权限列表(userId: 1,authority: 1， account: '') 其中account接口不传
 */
export const editDialogInfo: Ref<DialogInfo> = ref({
  taskId: 0,
  taskName: '',
  authorityListStr: [],
  taskMode: 0,
  formJson: '',
  picListStr: [],
  jobFileCount: 1,
  picQuality: 1,
  projectGroup: []
});

export const dialogShow = ref({
  mainDialog: false,
  memberDialog: false,
  programmeEditDialog: false,
  viewEditDialog: false,
  editContentDialog: false,
  logicSettingDialog: false,
  type: 'create',
  canEdit: false
});

export const getProblemList = (data: any) => {
  try {
    const utils = new Utils();
    const problemTemp = JSON.parse(JSON.stringify(data.layout));
    for (const problem of problemTemp) {
      for (const item of problem.fields) {
        //添加一个isshow属性
        item.isShow = true;
        //将value转成[]
        if (utils.isEmpty(item.value)) {
          item.value = [];
        }
        if (
          item.field_id === 'sebum_area_and_level' &&
          utils.isEmpty(item.selectValue)
        ) {
          item.selectValue = [];
        }

        if (item.type === 'field') {
          //将题目放入item里面
          for (const dataField of data.fields) {
            //查找对应的题目id
            if (dataField.id === item.field_id) {
              item.field = JSON.parse(JSON.stringify(dataField));
              //修改标题
              if ('title' in item) {
                item.field.title = item.title;
              }
            }
          }
        }
      }
    }
    return problemTemp;
  } catch (error) {
    console.log(error);
    return false;
  }
};
