/* eslint-disable @typescript-eslint/no-explicit-any */
import { MemberRole } from '@/enum/httpEnum';
import { CreateAxiosOptions, VAxios } from '@/services/http/Axios';
import * as CryptoJS from 'crypto-js';

export default class AtsApi extends VAxios {
  constructor(options: CreateAxiosOptions) {
    super(options);
    // console.log('[AtsApi Constructor]');
  }

  /** 登录 */
  login(account: string, password: string): Promise<any> {
    return this.post({
      url: 'login',
      data: {
        account,
        password: CryptoJS.MD5(password).toString()
      }
    });
  }

  /** 注册 */
  register(password: string, account: string, code: string): Promise<any> {
    return this.post({
      url: 'register',
      data: {
        password: CryptoJS.MD5(password).toString(),
        account,
        code
      }
    });
  }

  /** 换token */
  changeToken(): Promise<any> {
    return this.post({
      url: 'token'
    });
  }

  // 通过taskId获取taskItemList
  // mode 0: 编辑模式 1: 查看模式
  getJobItemList(jobId: string, mode: 0 | 1): Promise<any> {
    return this.get({
      url: 'task/job_item_list',
      params: {
        jobId,
        mode
      }
    });
  }

  // 更新答案
  updateFormDetail(
    jobId: string,
    itemId: string,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    formDetail: any
  ): Promise<any> {
    return this.post({
      url: 'task/job_item',
      data: {
        jobId,
        itemId,
        formDetail: JSON.stringify(formDetail)
      }
    });
  }

  // 通过itemId获取taskItemList
  // mode 0: 编辑模式  1: 查看模式
  getTaskItem(jobId: string, itemId: string, mode: 0 | 1): Promise<any> {
    return this.get({
      url: 'task/task_item',
      params: {
        jobId,
        itemId,
        mode
      }
    });
  }

  // 提交任务
  tagSubmitJob(jobId: string): Promise<any> {
    return this.post({
      url: 'task/submit_job',
      data: {
        jobId
      }
    });
  }

  // 提交任务
  examSubmitJob(jobId: string, comment: string): Promise<any> {
    return this.post({
      url: 'task/submit_job',
      data: {
        jobId,
        comment: comment
      }
    });
  }

  // 结果列表接口
  getItemList(data: string, listNo: string): Promise<any> {
    return this.get({
      url: 'task/result/item_list',
      params: {
        data: data,
        listNo: listNo
      }
    });
  }

  // 结果集获取结果接口
  getResultItem(jobId: string, itemId: string): Promise<any> {
    return this.get({
      url: 'task/result/result_item',
      params: {
        jobId,
        itemId
      }
    });
  }

  //获取用户列表接口
  getUserManageItem(
    page: number,
    rows: number,
    softType: 0 | 1,
    softWay: 0 | 1,
    account: string
  ): Promise<any> {
    const params: any = {
      page,
      rows,
      account
    };
    if (softType === 0) {
      params.modifierTimeSoft = softWay;
    } else {
      params.roleSoft = softWay;
    }
    return this.get({
      url: 'account/list',
      params
    });
  }
  //  增加用户信息接口
  addUserInfo(
    username: string,
    account: string,
    password: string
  ): Promise<any> {
    return this.post({
      url: 'account/create_account',
      data: {
        username,
        password: CryptoJS.MD5(password).toString(),
        account
      }
    });
  }
  //修改用户信息接口
  resetUserInfo(
    userId: number,
    username: string,
    password: string,
    account: string,
    delToken: boolean,
    oldPassword: string
  ): Promise<any> {
    return this.post({
      url: 'account/info',
      data: {
        userId,
        username,
        password: password !== '' ? CryptoJS.MD5(password).toString() : '',
        account,
        delToken,
        oldPassword:
          oldPassword !== '' ? CryptoJS.MD5(oldPassword).toString() : ''
      }
    });
  }

  //修改用户状态
  editUserStatu(userId: number, status: number): Promise<any> {
    return this.post({
      url: 'account/status',
      data: {
        userId,
        status
      }
    });
  }

  // 批量修改用户状态
  editUserGroupStatu(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    userIdList: any,
    status: number,
    role: number
  ): Promise<any> {
    return this.post({
      url: 'account/info_list',
      data: {
        data: JSON.stringify({
          userIdList: userIdList,
          status,
          role
        })
      }
    });
  }

  //修改用户角色
  editUserRole(userId: number, role: number): Promise<any> {
    return this.post({
      url: 'account/role',
      data: {
        userId,
        role
      }
    });
  }

  // 获取用户信息接口
  getUserInfo(userId: number): Promise<any> {
    return this.get({
      url: 'account/info',
      params: {
        userId
      }
    });
  }

  //获取管理任务列表
  getTaskManageItem(
    page: number,
    rows: number,
    modifierTimeSoft: number,
    taskName: string
  ): Promise<any> {
    return this.get({
      url: 'task/admin_task_list',
      params: {
        modifierTimeSoft,
        page,
        rows,
        taskName
      }
    });
  }

  //删除任务
  deleteManageTask(taskId: number, taskName: string): Promise<any> {
    return this.post({
      url: 'task/del',
      data: {
        taskId,
        taskName
      }
    });
  }

  // 获取任务详细信息
  getTaskInfo(taskId: number): Promise<any> {
    return this.get({
      url: 'task/task_info',
      params: {
        taskId
      }
    });
  }

  // 获取任务列表
  getTaskList(
    page: number,
    rows: number,
    taskName: string,
    taskNameSoft?: number,
    modifierTimeSoft?: number,
    creationTimeSoft?: number
  ): Promise<any> {
    const params: any = {
      page,
      rows,
      taskName
    };
    if (taskNameSoft) {
      params.taskNameSoft = taskNameSoft;
    }
    if (modifierTimeSoft) {
      params.modifierTimeSoft = modifierTimeSoft;
    }
    if (creationTimeSoft) {
      params.creationTimeSoft = creationTimeSoft;
    }
    return this.get({
      url: 'task/task_list',
      params: {
        page,
        rows,
        taskName,
        taskNameSoft,
        modifierTimeSoft,
        creationTimeSoft
      }
    });
  }

  // 获取置顶任务列表
  getTopTaskList(): Promise<any> {
    return this.get({
      url: 'task/top_task_list'
    });
  }

  // 置顶任务
  topTask(taskId: number): Promise<any> {
    return this.post({
      url: 'task/top',
      data: {
        taskId
      }
    });
  }

  // 取消置顶任务
  cancelTopTask(taskId: number): Promise<any> {
    return this.post({
      url: 'task/cancel_top',
      data: {
        taskId
      }
    });
  }

  // 删除任务
  deleteTask(taskId: number, taskName: string): Promise<any> {
    return this.post({
      url: 'task/del',
      data: {
        taskId,
        taskName
      }
    });
  }

  // 编辑任务标题
  // editTaskTitle(taskId: number, taskName: string): Promise<any> {
  //   return this.post({
  //     url: 'task/task_info',
  //     data: {
  //       taskId,
  //       taskName
  //     }
  //   });
  // }

  //添加成员
  addStaffRight(taskId: number, userId: string | number): Promise<any> {
    return this.post({
      url: '/task/creator',
      data: {
        taskId,
        userId
      }
    });
  }

  // 获取任务权限(任务列表分配权限，任务详情筛选均用到)
  getTaskAuthority(taskId: number): Promise<any> {
    return this.get({
      url: 'task/authority',
      params: {
        taskId
      }
    });
  }

  // 获取任务标注者列表
  getAnnotatorList(taskId: number): Promise<any> {
    return this.get({
      url: 'task/annotator',
      params: {
        taskId
      }
    });
  }

  // 修改任务权限(添加删除都用这个)
  editTaskAuthority(
    account: string,
    accountId: string,
    taskId: number,
    authority: MemberRole
  ): Promise<any> {
    return this.post({
      url: 'task/authority',
      data: {
        account,
        accountId,
        taskId,
        authority
      }
    });
  }

  //转让创建者
  editTaskCreator(userId: string, taskId: number): Promise<any> {
    return this.post({
      url: 'task/creator',
      data: {
        userId,
        taskId
      }
    });
  }

  // 获取子任务列表
  getJobList(
    taskId: number,
    userId: number, // 0: 所有 -1: 无
    sortType: 0 | 1, // 子任务id 更新时间
    sortWay: 0 | 1, // 0正序 1倒序
    page: number,
    rows: number
  ): Promise<any> {
    const params: any = {
      taskId,
      userId,
      page,
      rows
    };
    if (sortType === 0) {
      params.jobIdSoft = sortWay;
    } else if (sortType === 1) {
      params.modifierTimeSoft = sortWay;
    }
    return this.get({
      url: 'task/job_list',
      params
    });
  }

  // 分配子任务
  setJobAuthority(jobId: number, userId: number): Promise<any> {
    return this.post({
      url: 'task/job_authority',
      data: {
        jobId,
        userId
      }
    });
  }

  // 检查账户信息
  checkAccount(account: string): Promise<any> {
    return this.get({
      url: 'account/check_account',
      params: {
        account
      }
    });
  }

  // 本地上传图片
  uploadImg(fileList: any[]): Promise<any> {
    return this.post(
      {
        url: 'task/upload_mat_pic',
        data: {
          fileList
        }
      },
      undefined,
      'file'
    );
  }

  // 网络上传图片
  uploadImgWeb(fileUrlListStr: any[]): Promise<any> {
    return this.post({
      url: 'task/upload_mat_pic',
      data: {
        fileUrlListStr: JSON.stringify(fileUrlListStr)
      }
    });
  }

  /**
   * 创建任务
   * @param taskName 任务名称
   * @param authorityListStr 权限列表
   * @param taskMode 任务模式
   * @param formJson 题目
   * @param picListStr 标注文件列表
   * @param jobFileCount 子任务文件数
   * @param picQuality 文件质量
   */
  createTask(dialogInfo: {
    taskId: number;
    taskName: string;
    authorityListStr?: string;
    taskMode?: number;
    formJson?: string;
    picListStr?: string;
    jobFileCount?: number;
    picQuality?: number;
  }): Promise<any> {
    return this.post({
      url: 'task/create_mat_task',
      data: {
        taskId: dialogInfo.taskId,
        taskName: dialogInfo.taskName,
        authorityListStr: JSON.stringify(dialogInfo.authorityListStr),
        taskMode: dialogInfo.taskMode,
        formJson: dialogInfo.formJson,
        picListStr: JSON.stringify(dialogInfo.picListStr),
        jobFileCount: dialogInfo.jobFileCount,
        picQuality: dialogInfo.picQuality
      }
    });
  }

  /**
   * 获取任务成员列表
   * @param taskId 任务id
   * @param account 账号或用户名
   * @param authority 权限
   * @param startTime 开始时间
   * @param endTime 结束时间
   */
  getTaskMemberList(
    taskId: number,
    account: string,
    authority: number,
    startTime: number,
    endTime: number
  ): Promise<any> {
    return this.get({
      url: 'task/member',
      params: {
        taskId,
        account,
        authority,
        startTime,
        endTime
      }
    });
  }

  /**
   * 获取数据报表
   * @param mode 1: 按成员 2: 按子任务
   * @param timeSoft 0: 时间正序 2: 时间倒序
   * @param countSoft 0: 子任务完成数正序 1: 子任务完成数倒序
   * @param page
   * @param rows
   */
  getDataReportList(
    mode: 1 | 2,
    timeSoft: 0 | 1 | undefined,
    countSoft: 0 | 1 | undefined,
    page: number,
    rows: number
  ): Promise<any> {
    let params = {};
    if (mode === 1) {
      params = {
        countSoft
      };
    }
    return this.get({
      url: 'task/task_statistics',
      params: {
        ...params,
        mode,
        timeSoft,
        page,
        rows
      }
    });
  }
}
