
export interface FormDialog {
  // 在使用组件时，关闭组件回加载一个关闭函数closeEditDialog()，某个clickEvent使用该名称即可关闭弹窗，不一定要使用;
  title?: string; //标题
  showEditDialog: boolean; //必须传
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any;
  content?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params?: any;
    labelText?: string; //表单项文字区域 默认长度span=6通过textSpan设置长度
    textSpan?: number; //该表单的首部长度,最大为24
    inputSpan?: number; //表单输入部位的长度,最大为24**相对于剩余部分的宽度，非全部部分
    placeholder?: string; //占位符，分别应用于input 类型和drawer的头部
    typeOfInput?: string; //'text' | 'input' | 'radio' | 'checkbox' | 'select' |drawer;
    // 表单输入类型，可能为text 0 或input 1,radio 2,checkbox 3,select 4, 等 默认长度span12
    textType?: string; //输入类型。password
    maxlength?: number; //输入框的极限
    minlength?: number; //输入框的极限
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    content?: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      params?: any;
      labelText?: string; //表单项文字区域 默认长度span=6通过textSpan设置长度
      textSpan?: number; //该表单的首部长度,最大为24
      inputSpan?: number; //表单输入部位的长度,最大为24
      placeholder?: string; //占位符，分别应用于input 类型和drawer的头部
      typeOfInput?: string; //'text' | 'input' | 'radio' | 'checkbox' | 'select' |drawer;
      // 表单输入类型，可能为text 0 或input 1,radio 2,checkbox 3,select 4, 等 默认长度span12
      textType?: string; //输入类型。password
      maxlength?: number; //输入框的极限
      minlength?: number; //输入框的极限
      option?: {
        label?: string;
        value?: string | number | boolean;
        clickEvent?: string;
        checked?: boolean;
      }[]; // 子选项，为select,radio,checkbox,drawer等表单控件时使用 // drawer不能使用clickEvent
      remarkText?: string[]; //文字底下的提示多行可换行
      itemButton?: {
        name: string; // 按钮文字
        clickEvent: string; // 触发的事件名
        buttonType?: string; //'primary' | 'normal' | 'text'; //按钮类型
        disabled?: boolean; //不可点击
      }[]; //按钮列表
      //每个数组列表的clickEvent都会返回一个code,表示数组第几个值,在第一个位置,
      // 对于input ,函数第一个参数（e）为输入值，第二个是列表索引(其他的列表第一个返回的为索引)，可能会用到
      clickEvent?: string; //@change触发的事件名，也返回一个code
      //**使用drowdowm\checkbox\组件会返回参数（
      // {1、/列表的index*仅drowDowm有效，其他为0,
      // 2、外层的index}，可通过name:any获取到） */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      formValue?: number | string | any[] | boolean; //有表单组件时的绑定值; // checkbox为[]类型，其他的随意为string 或number,boolean
      showBottom?: boolean;
    }[]; //插槽
    option?: {
      label?: string;
      value?: string | number | boolean;
      clickEvent?: string;
      checked?: boolean;
    }[]; // 子选项，为select,radio,checkbox,drawer等表单控件时使用 // drawer不能使用clickEvent
    remarkText?: string[]; //文字底下的提示多行可换行
    itemButton?: {
      name: string; // 按钮文字
      clickEvent: string; // 触发的事件名
      buttonType?: string; //'primary' | 'normal' | 'text'; //按钮类型
      disabled?: boolean; //不可点击
    }[]; //按钮列表
    //每个数组列表的clickEvent都会返回一个code,表示数组第几个值,在第一个位置,
    // 对于input ,函数第一个参数（e）为输入值，第二个是列表索引(其他的列表第一个返回的为索引)，可能会用到
    clickEvent?: string; //@change触发的事件名，也返回一个code
    //**使用drowdowm\checkbox\组件会返回参数（
    // {1、/列表的index*仅drowDowm有效，其他为0,
    // 2、外层的index}，可通过name:any获取到） */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formValue?: number | string | any[] | boolean; //有表单组件时的绑定值; // checkbox为[]类型，其他的随意为string 或number,boolean
    showBottom?: boolean;
  }[]; // 表单项类弹窗
  bottomButton?: {
    name: string; // 按钮文字
    clickEvent: string; // 触发的事件名 **使用名为closeEditDialog也会触发关闭弹窗,以及关闭弹窗所携带的时间
    buttonType?: string; //'primary' | 'normal'; //按钮类型
    disabled?: boolean; //是否禁用
  }[]; //底下是否有按钮，也返回第一个参数为索引
}
import { defineComponent, ref, watch, Ref } from 'vue';
// 目前使用该组件需要传入以下结构
// struct{
//  title:string, //标题
// showEditDialog: showFirstEditDialog.value,//必须传
//  content:[
//  {
//    labelText:'',  //描述的左标题 默认长度span6
//    textSpan:0, 文字的长度
//    placeholder : //占位符
//    inputType:''    // 描述的右输入类型，可能为text 0 或input 1,radio 2,checkBox 3,select 4, 等 默认长度span12
//    option:[
//      {
//      label:'',
//      value:''
// }
//      ]  // 子选项，为select等表单控件时使用，可为空
//    remarkText:['',''] //文字底下的提示多行可换行
//    itemBotton:[
//    {
//        name: '修改账号', 按钮文字
//        clickEvent: 'accountNameEdit', 触发的事件名
//        type: 'text' 按钮类型
//        disabled:boolean 不可点击
//        },] //按钮列表
//    clickEvent:'', //@change触发的事件名
//    value:有表单组件时的绑定值
//    } // 表单类弹窗
//    ],
// bottomButton:['保存','取消'] //底下是否有按钮
export default defineComponent({
  name: 'EditDialog',
  props: {
    dialogInfo: {
      type: Object,
      default: () => {
        return {
          showEditDialog: false
        };
      }
    }
  },
  setup(props, context) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dialogInfoContent: Ref<FormDialog> = ref(props.dialogInfo as any);
    const parent = { ...context };
    // onMounted(async () => {
    // });
    // 订阅数据变更
    watch(
      () => props.dialogInfo,
      () => {
        //通过一个函数返回要监听的属性
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dialogInfoContent.value = props.dialogInfo as any;
      }
    );
    const closeEditDialog = () => {
      parent.emit('closeEditDialog');
      dialogInfoContent.value.showEditDialog = false;
    };
    const inputStatus = (
      e: string,
      event: string,
      contentIndex: number,
      index: number
    ) => {
      event
        ? parent.emit(event, {
            e: e,
            contentIndex: contentIndex,
            formValue: index
              ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (dialogInfoContent.value.content as any)[contentIndex].content[
                  index
                ].formValue
              : // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (dialogInfoContent.value.content as any)[contentIndex]
                  .formValue,
            index: index
          })
        : ''; //Index 用于定位
    };
    const itemListClick = (
      index: string,
      event: string,
      contentIndex: number
    ) => {
      if (event === 'closeEditDialog') {
        dialogInfoContent.value.showEditDialog = false;
      } else {
        parent.emit(event, {
          index: index,
          contentIndex: contentIndex
        });
      }
    };
    const itemGroupClick = (event: {
      event: string;
      index: number;
      contentIndex: number;
    }) => {
      parent.emit(event.event, {
        index: event.index,
        contentIndex: event.contentIndex,
        value: (dialogInfoContent.value.content as any)[event.contentIndex]
          .option[event.index].value,
        formValue: (dialogInfoContent.value.content as any)[event.contentIndex]
          .formValue
      });
    };
    const itemGroupClickInside = (event: any) => {
      parent.emit(event.event, {
        index: event.index,
        contentIndex: event.contentIndex,
        insiContenIndex: event.insiContentIndex,
        value: (dialogInfoContent.value.content as any)[event.contentIndex]
          .content[event.insiContentIndex].option[event.index].value,
        formValue: (dialogInfoContent.value.content as any)[event.contentIndex]
          .content[event.insiContentIndex].formValue
      });
    };
    return {
      inputStatus,
      itemListClick,
      closeEditDialog,
      itemGroupClick,
      dialogInfoContent,
      itemGroupClickInside
    };
  }
});
