const matTestUrl = 'api/';

const matUrl = 'api/';

const dev = {
  baseUrl: 'http://localhost:4200/'
  // wjc的mbp地址
  // baseUrl: 'http://192.168.0.100:4200/'
};

const test = {
  baseUrl: 'https://mat.sbox.meiyilab.com/'
};

const prod = {
  baseUrl: 'https://mat.meiyilab.com/'
};

export const env: {
  /** true - 生产环境，false - 开发环境/测试环境 */
  production: 'dev' | 'test' | 'prod';
  /** 接口地址 */
  baseUrl: string;
  /** mat地址 */
  matUrl: string;
} = {
  production:
    process.env.VUE_APP_MODE === 'prod'
      ? 'prod'
      : process.env.VUE_APP_MODE === 'test'
      ? 'test'
      : 'dev',
  baseUrl:
    process.env.VUE_APP_MODE === 'prod'
      ? prod.baseUrl
      : process.env.VUE_APP_MODE === 'test'
      ? test.baseUrl
      : dev.baseUrl,
  matUrl: process.env.VUE_APP_MODE === 'prod' ? matUrl : matTestUrl
};
