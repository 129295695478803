
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { defineComponent, onMounted, ref, watch } from 'vue';
import naviBar from '@/components/navi-bar.vue';
import router from '@/router';
export default defineComponent({
  // name: 'ZhProvider',
  components: {
    naviBar
  },
  setup() {
    const locale = zhCn;
    const showNavBar = ref(false);

    onMounted(async () => {
      // 订阅数据变更
      watch(
        () => router.currentRoute.value.path,
        () => {
          const enableNavBarList = ['/login', '/404'];
          showNavBar.value =
            enableNavBarList.indexOf(router.currentRoute.value.path) === -1;
        }
      );
    });
    return {
      locale,
      showNavBar
    };
  }
});
