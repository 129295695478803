import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { env } from './services/env';
import Utils from './utils/utils';
import user from './services/user';
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import './assets/theme/index.css';

createApp(App).use(router).use(ElementPlus).mount('#app');
const users = new user();
const utils = new Utils();
// const atsApi = new AtsApi({});

// 如果是生产环境
if (env.production === 'prod') {
  //
}

checktoken();

async function checktoken(): Promise<void> {
  // 返回的url
  const token = utils.getQueryVariable('token');
  if (token) {
    users.setCmsToken(token as string);
  }
  // // 没有传入token且缓存token已过期，这个逻辑放到路由里处理了
  // // 传入了token或者没有传token但是token没过期
  // if (!users.checkTokenTime()) {
  //   if (url) {
  //     users.setUrl(decodeURI(String(url)));
  //   }
  // }
}

// function getToken(): Promise<unknown> {
//   return new Promise((resolve, reject) => {
//     atsApi
//       .changeToken()
//       .then(
//         (res: { token: string; invalidTimeStamp: number; role: number }) => {
//           // 这里执行resolve逻辑
//           users.setTokenInfo(
//             res.token,
//             new Date().getTime() + res.invalidTimeStamp,
//             res.invalidTimeStamp,
//             res.role
//           );
//           resolve(true);
//         }
//       )
//       .catch((err: ApiError) => {
//         alert(err.msg ? err.msg : '未知错误');
//         reject();
//       });
//   });
// }
