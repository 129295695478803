<template>
  <div class="infoBox">
    <div
      v-for="(tempMenu, index) in menu"
      :key="index"
      @click="checkMenu(tempMenu.type)"
      class="item"
      :style="index === menu.length - 1 ? `border-bottom-width: 0px;` : ``"
    >
      {{ tempMenu.title }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
export default defineComponent({
  name: 'EditMenu',
  props: {
    menu: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  setup(props, context) {
    onMounted(async () => {
      // 订阅数据变更
    });
    const checkMenu = (type: string) => {
      context.emit('checkMenu', type);
    };
    return {
      checkMenu
    };
  }
});
</script>

<style lang="scss" scoped>
.infoBox {
  position: absolute;
  font-size: 20px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  z-index: 9;
  margin-top: 5px;

  .item {
    display: flex;
    justify-content: center;
    padding: 7px 13px 7px 17px;
    color: #333;
    font-size: 14px;
    letter-spacing: 4px;
    cursor: pointer;
  }
}
</style>
