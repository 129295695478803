/* eslint-disable @typescript-eslint/no-explicit-any */
import { ElMessage } from 'element-plus';
import { ElLoading } from 'element-plus';

export declare type messageOptions = {
  msg?: string;
  type?: 'success' | 'warning' | 'error' | 'info';
};

export declare type alertOptions = {
  title?: string;
  msg?: string;
  type: 'success' | 'warning' | 'error' | 'info';
};

export default class Tips {
  private loadingInstance: any;
  /**
   * 弹出信息层
   * @param msg 信息
   * @param type 类型
   */
  message(options?: messageOptions): void {
    let type = '';
    let msg = '';
    if (!options) {
      type = 'info';
      msg = '';
    } else {
      type = options.type ? options.type : 'info';
      msg = options.msg ? options.msg : '';
    }
    switch (type) {
      case 'success':
        ElMessage.success({
          message: msg,
          type: 'success'
        });
        break;
      case 'warning':
        ElMessage.warning({
          message: msg,
          type: 'warning'
        });
        break;
      case 'error':
        ElMessage.error({
          message: msg,
          type: 'error'
        });
        break;
      case 'info':
        ElMessage.info({
          message: msg,
          type: 'info'
        });
        break;
    }
  }

  /**
   * 加载loading
   */
  public loading = {
    create: (msg = '加载中...'): void => {
      this.loadingInstance = ElLoading.service({
        text: msg,
        spinner: 'el-icon-loading'
      });
    },

    close: (): void => {
      this.loadingInstance.close();
    }
  };
}
