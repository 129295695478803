
import { defineComponent, ref, onMounted, Ref } from 'vue';
import EditMenu from './edit-menu.vue';
import router from '@/router';
import EditDialog, { FormDialog } from '@/components/edit-dialog.vue';
import user from '@/services/user';
import AtsApi from '@/services/AtsApi';
import { ApiError } from '@/services/http/Axios';
import Tips from '@/utils/tips';
import { isString } from '@/utils/is';
// import EditDialog from './edit-dia.vue';
export default defineComponent({
  name: 'naviBar',
  components: {
    EditMenu,
    EditDialog
  },
  props: {},
  setup() {
    const users = new user();
    const atsApi = new AtsApi({});
    const tips = new Tips();
    const account1 = ref('');
    const userName = ref('');
    const userId = ref(0);
    const hideMenuList = ref({
      content: true,
      menu: true
    });
    const dialogInfo: Ref<FormDialog> = ref({
      showEditDialog: false,
      title: '',
      content: [
        {
          labelText: '用户名',
          typeOfInput: 'input',
          formValue: '',
          remarkText: ['6-20个字符，可以是汉字、字母'],
          minlength: 6,
          maxlength: 20,
          showBottom: true
        },
        {
          labelText: '账号',
          typeOfInput: 'text',
          formValue: '',
          inputSpan: 10,
          showBottom: true,
          itemButton: [
            {
              name: '修改账号',
              clickEvent: 'accountNameEdit',
              buttonType: 'text'
            }
          ]
        },
        {
          labelText: '密码',
          itemButton: [
            {
              name: '重置密码',
              clickEvent: 'accountOpera',
              buttonType: 'text'
            }
          ]
        }
      ],
      bottomButton: [
        {
          name: '取消',
          clickEvent: 'closeEditDialog'
        },
        {
          name: '保存',

          buttonType: 'primary',
          clickEvent: 'bottomClick'
        }
      ]
    });
    const dialogInfo2: Ref<FormDialog> = ref({
      showEditDialog: false,
      title: '',
      content: [
        {
          labelText: '当前密码',
          typeOfInput: 'input',
          formValue: '',
          textType: 'password',
          minlength: 6,
          maxlength: 20
        },
        {
          labelText: '新密码',
          typeOfInput: 'input',
          formValue: '',
          textType: 'password',
          remarkText: ['6-20个字符'],
          clickEvent: 'inputNewPassword',
          minlength: 6,
          maxlength: 20
        },
        {
          labelText: '确认新密码',
          formValue: '',
          remarkText: [],
          typeOfInput: 'input',
          textType: 'password',
          clickEvent: 'inputNewPasswordCheck',
          minlength: 6,
          maxlength: 20
        }
      ],
      bottomButton: [
        {
          name: '取消',
          clickEvent: 'closeEditDialog'
        },
        {
          name: '确定',
          buttonType: 'primary',
          clickEvent: 'savaNewPassword'
        }
      ]
    });
    const dialogInfo3 = ref({
      showEditDialog: false,
      title: '',
      content: [
        {
          typeOfInput: 'input',
          formValue: '',
          textType: 'text',
          placeholder: '新账号（手机号或邮箱）'
        },
        {
          typeOfInput: 'input',
          formValue: '',
          textType: 'password',
          placeholder: '输入密码以确认修改'
        }
      ],
      bottomButton: [
        {
          name: '取消',
          clickEvent: 'closeEditDialog'
        },
        {
          name: '确定',
          buttonType: 'primary',
          clickEvent: 'savaNewAccount'
        }
      ]
    });
    const tempPassword: string[] = ['', ''];
    const menu = [
      {
        title: '编辑',
        type: 'edit'
      },
      {
        title: '退出',
        type: 'exit'
      }
    ];
    const directionChange = ref(false);

    onMounted(async () => {
      const temp = users.getUserInfo();
      account1.value = temp.account;
      userName.value = temp.username;
      userId.value = temp.userId;
    });

    const enterContent = () => {
      hideMenuList.value.content = false;
    };

    const showWindow = () => {
      if (!directionChange.value) {
        directionChange.value = true;
      }
    };

    const hideWindow = (type: 'content' | 'menu') => {
      if (type === 'content') {
        hideMenuList.value.content = true;
      } else {
        hideMenuList.value.menu = true;
      }
      setTimeout(() => {
        if (hideMenuList.value.content && hideMenuList.value.menu) {
          directionChange.value = false;
        }
      }, 500);
    };
    // menu被选择
    const checkMenu = async (e: 'edit' | 'exit') => {
      // 退出
      if (e === 'exit') {
        users.clearAllUserInfo();
        router.replace({
          path: '/login'
        });
      }
      if (e === 'edit') {
        // await getUserInfo();
        dialogInfo.value.showEditDialog = true;
        dialogInfo.value.title = userName.value + ' 账户信息';
        dialogInfo.value.content
          ? ((dialogInfo.value.content[0].formValue = userName.value),
            (dialogInfo.value.content[1].formValue = account1.value))
          : '';
      }
    };
    const resetUserInfo = async (
      userId: number,
      username: string,
      password: string,
      account: string,
      delToken: boolean,
      currentPassword: string,
      closeDialogType: number //  0 修改用户名 1 修改密码 2 账号信息
      // closoDialogType 关闭第closoDialogType +1弹窗。。。
    ) => {
      console.log(
        userId,
        username,
        password,
        account,
        delToken,
        currentPassword,
        closeDialogType
      );
      return new Promise(resolve => {
        atsApi
          .resetUserInfo(
            userId,
            username,
            password,
            account,
            delToken,
            currentPassword
          )
          .then(async res => {
            console.log(account);
            closeDialogType === 0
              ? (tips.message({
                  msg: res.msg ? res.msg : '修改用户名成功',
                  type: 'success'
                }),
                (dialogInfo.value.showEditDialog = false))
              : closeDialogType === 1
              ? (tips.message({
                  msg: res.msg ? res.msg : '修改密码成功',
                  type: 'success'
                }),
                (dialogInfo2.value.showEditDialog = false))
              : (tips.message({
                  msg: res.msg ? res.msg : '修改账号成功',
                  type: 'success'
                }),
                (dialogInfo3.value.showEditDialog = false),
                (account1.value = account),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ((dialogInfo.value as any).content[1].formValue = account));
            users.setUserInfo({
              ...users.getUserInfo(),
              username,
              account: closeDialogType === 2 ? account : account1.value
            });
            resolve(res);
          })
          .catch((err: ApiError) => {
            tips.message({
              msg: err.msg ? err.msg : '未知错误',
              type: 'error'
            });
            resolve(err);
          });
      });
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const inputNewPasswordCheck = (e: any) => {
      if (e.e.length < 6) {
        dialogInfo2.value.content
          ? dialogInfo2.value.content[2].remarkText
            ? (dialogInfo2.value.content[2].remarkText[0] = '输入完整密码')
            : ''
          : '';
      } else {
        if (e.e.lenth === 0) {
          dialogInfo2.value.content
            ? dialogInfo2.value.content[2].remarkText
              ? (dialogInfo2.value.content[2].remarkText[0] = '')
              : ''
            : '';
        } else {
          if (e.e !== tempPassword[0]) {
            dialogInfo2.value.content
              ? dialogInfo2.value.content[2].remarkText
                ? (dialogInfo2.value.content[2].remarkText[0] =
                    '两次密码不匹配')
                : ''
              : '';
          } else {
            dialogInfo2.value.content
              ? dialogInfo2.value.content[2].remarkText
                ? (dialogInfo2.value.content[2].remarkText[0] = '')
                : ''
              : '';
            tempPassword[1] = e.e;
          }
        }
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const inputNewPassword = (e: any) => {
      tempPassword[0] = e.e;
      dialogInfo2.value.content
        ? dialogInfo2.value.content[2].formValue
          ? (dialogInfo2.value.content[2].formValue = '')
          : ''
        : '';
    };
    const savaNewPassword = async () => {
      if (tempPassword[0].length !== tempPassword[1].length) {
        return;
      } else if (tempPassword[0] !== tempPassword[1]) {
        return;
      } else if (tempPassword[0].length < 6 || tempPassword[1].length < 6) {
        return;
      } else {
        const password = tempPassword[1];
        const currentPassword = (dialogInfo2.value.content as any)[0].formValue;
        await resetUserInfo(
          userId.value,
          userName.value,
          password,
          account1.value,
          false,
          isString(currentPassword) ? currentPassword : '',
          1
        );
      }
    };
    const savaAccountInfo = async () => {
      const accountNameTemp = dialogInfo.value.content
        ? dialogInfo.value.content[0].formValue
        : '';
      if (isString(accountNameTemp)) {
        await resetUserInfo(
          userId.value,
          accountNameTemp,
          '',
          '',
          false,
          '',
          0
        );
      }
      const temp = users.getUserInfo();
      userName.value = temp.username;
    };
    const savaNewAccount = async () => {
      const accountTemp = dialogInfo3.value.content[0].formValue;
      const passwordTemp = dialogInfo3.value.content[1].formValue;
      if (accountTemp.length !== 0 && passwordTemp.length !== 0) {
        await resetUserInfo(
          userId.value,
          userName.value,
          '',
          accountTemp,
          false,
          passwordTemp,
          2
        );
      } else {
        tips.message({
          msg: '未填写完整信息',
          type: 'error'
        });
      }
    };
    const goHome = () => {
      router.replace({
        path: '/home'
      });
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const accountOpera = (e: any) => {
      //重置密码操作
      console.log(e);
      switch (e.index) {
        case 0:
          {
            dialogInfo2.value.showEditDialog = true;
            dialogInfo2.value.title = userName.value + '重置密码';
          }
          break;
        default:
          break;
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const accountNameEdit = (e: any) => {
      //修改账号
      switch (e.index) {
        case 0:
          {
            dialogInfo3.value.title = userName.value + ' 修改账号';
            dialogInfo3.value.showEditDialog = true;
          }
          break;
        default:
          break;
      }
    };
    return {
      menu,
      directionChange,
      account1,
      userName,
      userId,
      hideMenuList,
      dialogInfo,
      dialogInfo2,
      dialogInfo3,
      inputNewPasswordCheck,
      inputNewPassword,
      savaNewPassword,
      enterContent,
      showWindow,
      hideWindow,
      checkMenu,
      goHome,
      accountOpera,
      savaAccountInfo,
      accountNameEdit,
      savaNewAccount
    };
  }
});
