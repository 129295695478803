<template>
  <div class="infoBox">
    <el-dialog
      :append-to-body="true"
      v-model="dialogInfoContent.showEditDialog"
      :title="dialogInfoContent.title ? dialogInfoContent.title : ''"
      width="40%"
      :show-close="true"
      @close="closeEditDialog"
    >
      <div v-if="dialogInfoContent.content">
        <el-form :inline="true">
          <el-row
            v-for="(item, index) in dialogInfoContent.content"
            :key="index"
            :style="
              item.showBottom
                ? index === dialogInfoContent.content.length - 1
                  ? `padding: 15px 0px;`
                  : `padding: 15px 0px; border-bottom: 1px solid #e5e5e5`
                : `padding: 15px 0px;`
            "
          >
            <el-col
              :span="item.textSpan ? item.textSpan : 6"
              v-if="item.labelText"
              style="display: flex; align-items: center"
            >
              {{ item.labelText }}
            </el-col>

            <el-col
              :span="
                item.labelText
                  ? item.textSpan
                    ? item.textSpan !== 24
                      ? 24 - item.textSpan
                      : 24
                    : 18
                  : 24
              "
            >
              <template v-if="item.content">
                <el-form :inline="true">
                  <el-row
                    v-for="(item1, index1) in item.content"
                    :key="index1"
                    :style="
                      item1.showBottom
                        ? `padding: 15px 0px; border-bottom: 1px solid #e5e5e5`
                        : `padding: 15px 0px;`
                    "
                  >
                    <el-col
                      :span="item1.textSpan ? item1.textSpan : 6"
                      v-if="item1.labelText"
                      style="display: flex; align-items: center"
                    >
                      {{ item1.labelText }}
                    </el-col>

                    <el-col
                      :span="
                        item1.labelText
                          ? item1.textSpan === 24
                            ? 24
                            : 24 - item1.textSpan
                          : 18
                      "
                    >
                      <el-row>
                        <el-col
                          v-if="item1.typeOfInput === 'text'"
                          style="display: flex; align-items: center"
                        >
                          <span>
                            {{ item1.formValue }}
                          </span></el-col
                        >
                        <el-col v-if="item1.typeOfInput === 'input'">
                          <el-input
                            :span="item1.inputSpan ? item1.inputSpan : 18"
                            v-model="item1.formValue"
                            @input="
                              item1.clickEvent
                                ? inputStatus(
                                    item1.formValue,
                                    item1.clickEvent ? item1.clickEvent : '',
                                    index,
                                    index1
                                  )
                                : ''
                            "
                            :placeholder="item1.placeholder"
                            :maxlength="item1.maxlength ? item1.maxlength : 0"
                            :minlength="item1.minlength ? item1.maxlength : 20"
                            :type="item1.textType ? item1.textType : 'text'"
                            :disabled="item1.disabled ? item1.disabled : false"
                          ></el-input>
                        </el-col>
                        <el-col
                          :span="item1.itemButton.length * 6"
                          v-if="item1.itemButton"
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          "
                        >
                          <el-button
                            :span="6"
                            size="small"
                            :type="buttonlist.buttonType"
                            v-for="(buttonlist, bottonNo) in item1.itemButton"
                            :key="bottonNo"
                            @click="
                              buttonlist.clickEvent
                                ? itemListClick(
                                    bottonNo,
                                    buttonlist.clickEvent,
                                    index
                                  )
                                : ''
                            "
                            :disabled="
                              buttonlist.disabled ? buttonlist.disabled : ''
                            "
                            >{{ buttonlist.name }}</el-button
                          >
                        </el-col>
                        <el-col
                          :span="item1.inputSpan ? item1.inputSpan : 18"
                          v-if="item1.typeOfInput === 'checkbox'"
                        >
                          <el-checkbox-group
                            v-model="item1.formValue"
                            @change="
                              item1.clickEvent
                                ? itemGroupClick({
                                    index: 0,
                                    event: item1.clickEvent
                                      ? item1.clickEvent
                                      : '',
                                    contentIndex: index
                                  })
                                : ''
                            "
                          >
                            <el-row>
                              <el-checkbox
                                v-for="(checkbox, checkboxNo) in item1.option"
                                :key="checkboxNo"
                                :label="checkbox.value"
                                :checked="
                                  checkbox.checked ? checkbox.checked : false
                                "
                                @click="
                                  checkbox.clickEvent
                                    ? itemListClick(
                                        checkboxNo,
                                        checkbox.clickEvent,
                                        index
                                      )
                                    : ''
                                "
                                >{{ checkbox.label }}</el-checkbox
                              >
                            </el-row>
                          </el-checkbox-group>
                        </el-col>
                        <el-col v-if="item1.typeOfInput === 'select'">
                          <el-select
                            v-model="item1.formValue"
                            @change="
                              item1.clickEvent
                                ? itemGroupClick({
                                    index: 0,
                                    event: item1.clickEvent
                                      ? item1.clickEvent
                                      : '',
                                    contentIndex: index
                                  })
                                : ''
                            "
                          >
                            <el-option
                              v-for="(selector, selectNo) in item1.option"
                              :key="selectNo"
                              :label="selector.label"
                              :value="selector.value"
                              @click="
                                selector.clickEvent
                                  ? itemListClick(
                                      selectNo,
                                      selector.clickEvent,
                                      index,
                                      index1
                                    )
                                  : ''
                              "
                              :disabled="
                                selector.disabled ? selector.disabled : ''
                              "
                            >
                            </el-option>
                          </el-select>
                        </el-col>
                        <el-col v-if="item1.typeOfInput === 'drawer'">
                          <el-dropdown @command="itemGroupClickInside">
                            <span class="el-dropdown-link">
                              {{ item1.placeholder
                              }}<i
                                class="el-icon-arrow-down el-icon--right"
                              ></i>
                            </span>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <el-dropdown-item
                                  v-for="(menu, selectNo) in item1.option"
                                  :key="selectNo"
                                  :command="{
                                    index: selectNo,
                                    event: menu.clickEvent
                                      ? menu.clickEvent
                                      : item1.clickEvent,
                                    contentIndex: index,
                                    insiContentIndex: index1
                                  }"
                                  :disabled="
                                    menu.disabled ? menu.disabled : false
                                  "
                                >
                                  {{ menu.label }}
                                </el-dropdown-item></el-dropdown-menu
                              >
                            </template>
                          </el-dropdown>
                        </el-col>
                      </el-row>
                      <el-row v-if="item1.remarkText">
                        <span
                          v-for="(remark, index) in item1.remarkText"
                          :key="index"
                          class="remarkText"
                          >{{ remark }}</span
                        ></el-row
                      >
                    </el-col>
                  </el-row></el-form
                >
              </template>
              <el-row>
                <!-- 文字 -->
                <el-col
                  :span="item.inputSpan ? item.inputSpan : 18"
                  v-if="item.typeOfInput === 'text'"
                  style="display: flex; align-items: center"
                >
                  <span>
                    {{ item.formValue }}
                  </span></el-col
                >
                <!-- 输入框 -->
                <el-col
                  :span="item.inputSpan ? item.inputSpan : 18"
                  v-if="item.typeOfInput === 'input'"
                >
                  <el-input
                    v-model="item.formValue"
                    @input="
                      item.clickEvent
                        ? inputStatus(
                            item.formValue,
                            item.clickEvent ? item.clickEvent : '',
                            index
                          )
                        : ''
                    "
                    :placeholder="item.placeholder"
                    :maxlength="item.maxlength ? item.maxlength : 500"
                    :minlength="item.minlength ? item.maxlength : 0"
                    :type="item.textType ? item.textType : 'text'"
                    :disabled="item.disabled ? item.disabled : false"
                  ></el-input>
                </el-col>
                <!-- 按钮 -->
                <el-col
                  :span="item.itemButton.length * 6"
                  v-if="item.itemButton"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <el-button
                    :span="6"
                    size="small"
                    :type="buttonlist.buttonType"
                    v-for="(buttonlist, bottonNo) in item.itemButton"
                    :key="bottonNo"
                    @click="
                      buttonlist.clickEvent
                        ? itemListClick(bottonNo, buttonlist.clickEvent, index)
                        : ''
                    "
                    :disabled="
                      buttonlist.disabled ? buttonlist.disabled : false
                    "
                    >{{ buttonlist.name }}</el-button
                  >
                </el-col>
                <!-- 多选框 -->
                <el-col
                  :span="item.inputSpan ? item.inputSpan : 18"
                  v-if="item.typeOfInput === 'checkbox'"
                >
                  <el-checkbox-group
                    v-model="item.formValue"
                    @change="
                      item.clickEvent
                        ? itemGroupClick({
                            index: 0,
                            event: item.clickEvent ? item.clickEvent : '',
                            contentIndex: index
                          })
                        : ''
                    "
                  >
                    <el-row
                      v-for="(checkbox, checkboxNo) in item.option"
                      :key="checkboxNo"
                    >
                      <el-checkbox
                        :label="checkbox.value"
                        :checked="checkbox.checked ? checkbox.checked : false"
                        @click="
                          checkbox.clickEvent
                            ? itemListClick(
                                checkboxNo,
                                checkbox.clickEvent,
                                index
                              )
                            : ''
                        "
                        :disabled="
                          checkbox.disabled ? checkbox.disabled : false
                        "
                        >{{ checkbox.label }}</el-checkbox
                      >
                    </el-row>
                  </el-checkbox-group>
                </el-col>
                <!-- 单选框 -->
                <el-col
                  :span="item.inputSpan ? item.inputSpan : 18"
                  v-if="item.typeOfInput === 'radio'"
                >
                  <el-radio-group
                    v-model="item.formValue"
                    @change="
                      item.clickEvent
                        ? itemGroupClick({
                            index: 0,
                            event: item.clickEvent ? item.clickEvent : '',
                            contentIndex: index
                          })
                        : ''
                    "
                  >
                    <el-row
                      v-for="(radio, radioNo) in item.option"
                      :key="radioNo"
                    >
                      <el-radio
                        :label="radio.value"
                        :checked="radio.checked ? radio.checked : false"
                        @click="
                          radio.clickEvent
                            ? itemListClick(radio, radio.clickEvent, index)
                            : ''
                        "
                        :disabled="radio.disabled ? radio.disabled : false"
                        >{{ radio.label }}</el-radio
                      >
                    </el-row>
                  </el-radio-group>
                </el-col>
                <!-- 选择框 -->
                <el-col
                  :span="item.inputSpan ? item.inputSpan : 12"
                  v-if="item.typeOfInput === 'select'"
                >
                  <el-select
                    v-model="item.formValue"
                    @change="
                      item.clickEvent
                        ? itemGroupClick({
                            index: 0,
                            event: item.clickEvent ? item.clickEvent : '',
                            contentIndex: index
                          })
                        : ''
                    "
                  >
                    <el-option
                      v-for="(selector, selectNo) in item.option"
                      :key="selectNo"
                      :label="selector.label"
                      :value="selector.value"
                      @click="
                        selector.clickEvent
                          ? itemListClick(selectNo, selector.clickEvent, index)
                          : ''
                      "
                      :disabled="selector.disabled ? selector.disabled : false"
                    >
                    </el-option>
                  </el-select>
                </el-col>
                <!-- 下拉菜单 -->
                <el-col
                  :span="item.inputSpan ? item.inputSpan : 12"
                  v-if="item.typeOfInput === 'drawer'"
                >
                  <el-dropdown @command="itemGroupClick">
                    <span class="el-dropdown-link">
                      {{ item.placeholder
                      }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          v-for="(menu, selectNo) in item.option"
                          :key="selectNo"
                          :command="{
                            index: selectNo,
                            event: menu.clickEvent
                              ? menu.clickEvent
                              : item.clickEvent,
                            contentIndex: index
                          }"
                          :disabled="menu.disabled ? menu.disabled : false"
                        >
                          {{ menu.label }}
                        </el-dropdown-item></el-dropdown-menu
                      >
                    </template>
                  </el-dropdown>
                </el-col>
              </el-row>
              <!-- 备注文字，可能会换行 -->
              <el-row v-if="item.remarkText">
                <span
                  v-for="(remark, index) in item.remarkText"
                  :key="index"
                  class="remarkText"
                  >{{ remark }}</span
                ></el-row
              >
            </el-col>
          </el-row></el-form
        >
      </div>
      <!-- 底部按钮 -->
      <template #footer v-if="dialogInfoContent.bottomButton">
        <span class="dialog-footer">
          <el-button
            size="small"
            :type="buttonlist.buttonType"
            v-for="(buttonlist, no) in dialogInfoContent.bottomButton"
            :key="no"
            :disabled="buttonlist.disabled"
            @click="itemListClick(no, buttonlist.clickEvent)"
            >{{ buttonlist.name }}</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
export interface FormDialog {
  // 在使用组件时，关闭组件回加载一个关闭函数closeEditDialog()，某个clickEvent使用该名称即可关闭弹窗，不一定要使用;
  title?: string; //标题
  showEditDialog: boolean; //必须传
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any;
  content?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params?: any;
    labelText?: string; //表单项文字区域 默认长度span=6通过textSpan设置长度
    textSpan?: number; //该表单的首部长度,最大为24
    inputSpan?: number; //表单输入部位的长度,最大为24**相对于剩余部分的宽度，非全部部分
    placeholder?: string; //占位符，分别应用于input 类型和drawer的头部
    typeOfInput?: string; //'text' | 'input' | 'radio' | 'checkbox' | 'select' |drawer;
    // 表单输入类型，可能为text 0 或input 1,radio 2,checkbox 3,select 4, 等 默认长度span12
    textType?: string; //输入类型。password
    maxlength?: number; //输入框的极限
    minlength?: number; //输入框的极限
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    content?: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      params?: any;
      labelText?: string; //表单项文字区域 默认长度span=6通过textSpan设置长度
      textSpan?: number; //该表单的首部长度,最大为24
      inputSpan?: number; //表单输入部位的长度,最大为24
      placeholder?: string; //占位符，分别应用于input 类型和drawer的头部
      typeOfInput?: string; //'text' | 'input' | 'radio' | 'checkbox' | 'select' |drawer;
      // 表单输入类型，可能为text 0 或input 1,radio 2,checkbox 3,select 4, 等 默认长度span12
      textType?: string; //输入类型。password
      maxlength?: number; //输入框的极限
      minlength?: number; //输入框的极限
      option?: {
        label?: string;
        value?: string | number | boolean;
        clickEvent?: string;
        checked?: boolean;
      }[]; // 子选项，为select,radio,checkbox,drawer等表单控件时使用 // drawer不能使用clickEvent
      remarkText?: string[]; //文字底下的提示多行可换行
      itemButton?: {
        name: string; // 按钮文字
        clickEvent: string; // 触发的事件名
        buttonType?: string; //'primary' | 'normal' | 'text'; //按钮类型
        disabled?: boolean; //不可点击
      }[]; //按钮列表
      //每个数组列表的clickEvent都会返回一个code,表示数组第几个值,在第一个位置,
      // 对于input ,函数第一个参数（e）为输入值，第二个是列表索引(其他的列表第一个返回的为索引)，可能会用到
      clickEvent?: string; //@change触发的事件名，也返回一个code
      //**使用drowdowm\checkbox\组件会返回参数（
      // {1、/列表的index*仅drowDowm有效，其他为0,
      // 2、外层的index}，可通过name:any获取到） */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      formValue?: number | string | any[] | boolean; //有表单组件时的绑定值; // checkbox为[]类型，其他的随意为string 或number,boolean
      showBottom?: boolean;
    }[]; //插槽
    option?: {
      label?: string;
      value?: string | number | boolean;
      clickEvent?: string;
      checked?: boolean;
    }[]; // 子选项，为select,radio,checkbox,drawer等表单控件时使用 // drawer不能使用clickEvent
    remarkText?: string[]; //文字底下的提示多行可换行
    itemButton?: {
      name: string; // 按钮文字
      clickEvent: string; // 触发的事件名
      buttonType?: string; //'primary' | 'normal' | 'text'; //按钮类型
      disabled?: boolean; //不可点击
    }[]; //按钮列表
    //每个数组列表的clickEvent都会返回一个code,表示数组第几个值,在第一个位置,
    // 对于input ,函数第一个参数（e）为输入值，第二个是列表索引(其他的列表第一个返回的为索引)，可能会用到
    clickEvent?: string; //@change触发的事件名，也返回一个code
    //**使用drowdowm\checkbox\组件会返回参数（
    // {1、/列表的index*仅drowDowm有效，其他为0,
    // 2、外层的index}，可通过name:any获取到） */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formValue?: number | string | any[] | boolean; //有表单组件时的绑定值; // checkbox为[]类型，其他的随意为string 或number,boolean
    showBottom?: boolean;
  }[]; // 表单项类弹窗
  bottomButton?: {
    name: string; // 按钮文字
    clickEvent: string; // 触发的事件名 **使用名为closeEditDialog也会触发关闭弹窗,以及关闭弹窗所携带的时间
    buttonType?: string; //'primary' | 'normal'; //按钮类型
    disabled?: boolean; //是否禁用
  }[]; //底下是否有按钮，也返回第一个参数为索引
}
import { defineComponent, ref, watch, Ref } from 'vue';
// 目前使用该组件需要传入以下结构
// struct{
//  title:string, //标题
// showEditDialog: showFirstEditDialog.value,//必须传
//  content:[
//  {
//    labelText:'',  //描述的左标题 默认长度span6
//    textSpan:0, 文字的长度
//    placeholder : //占位符
//    inputType:''    // 描述的右输入类型，可能为text 0 或input 1,radio 2,checkBox 3,select 4, 等 默认长度span12
//    option:[
//      {
//      label:'',
//      value:''
// }
//      ]  // 子选项，为select等表单控件时使用，可为空
//    remarkText:['',''] //文字底下的提示多行可换行
//    itemBotton:[
//    {
//        name: '修改账号', 按钮文字
//        clickEvent: 'accountNameEdit', 触发的事件名
//        type: 'text' 按钮类型
//        disabled:boolean 不可点击
//        },] //按钮列表
//    clickEvent:'', //@change触发的事件名
//    value:有表单组件时的绑定值
//    } // 表单类弹窗
//    ],
// bottomButton:['保存','取消'] //底下是否有按钮
export default defineComponent({
  name: 'EditDialog',
  props: {
    dialogInfo: {
      type: Object,
      default: () => {
        return {
          showEditDialog: false
        };
      }
    }
  },
  setup(props, context) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dialogInfoContent: Ref<FormDialog> = ref(props.dialogInfo as any);
    const parent = { ...context };
    // onMounted(async () => {
    // });
    // 订阅数据变更
    watch(
      () => props.dialogInfo,
      () => {
        //通过一个函数返回要监听的属性
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dialogInfoContent.value = props.dialogInfo as any;
      }
    );
    const closeEditDialog = () => {
      parent.emit('closeEditDialog');
      dialogInfoContent.value.showEditDialog = false;
    };
    const inputStatus = (
      e: string,
      event: string,
      contentIndex: number,
      index: number
    ) => {
      event
        ? parent.emit(event, {
            e: e,
            contentIndex: contentIndex,
            formValue: index
              ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (dialogInfoContent.value.content as any)[contentIndex].content[
                  index
                ].formValue
              : // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (dialogInfoContent.value.content as any)[contentIndex]
                  .formValue,
            index: index
          })
        : ''; //Index 用于定位
    };
    const itemListClick = (
      index: string,
      event: string,
      contentIndex: number
    ) => {
      if (event === 'closeEditDialog') {
        dialogInfoContent.value.showEditDialog = false;
      } else {
        parent.emit(event, {
          index: index,
          contentIndex: contentIndex
        });
      }
    };
    const itemGroupClick = (event: {
      event: string;
      index: number;
      contentIndex: number;
    }) => {
      parent.emit(event.event, {
        index: event.index,
        contentIndex: event.contentIndex,
        value: (dialogInfoContent.value.content as any)[event.contentIndex]
          .option[event.index].value,
        formValue: (dialogInfoContent.value.content as any)[event.contentIndex]
          .formValue
      });
    };
    const itemGroupClickInside = (event: any) => {
      parent.emit(event.event, {
        index: event.index,
        contentIndex: event.contentIndex,
        insiContenIndex: event.insiContentIndex,
        value: (dialogInfoContent.value.content as any)[event.contentIndex]
          .content[event.insiContentIndex].option[event.index].value,
        formValue: (dialogInfoContent.value.content as any)[event.contentIndex]
          .content[event.insiContentIndex].formValue
      });
    };
    return {
      inputStatus,
      itemListClick,
      closeEditDialog,
      itemGroupClick,
      dialogInfoContent,
      itemGroupClickInside
    };
  }
});
</script>

<style lang="scss" scoped>
.infoBox {
  position: absolute;
  font-size: 20px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 15%);
  z-index: 9;
  .main-dialog :deep(.el-overlay) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .item {
    display: flex;
    justify-content: center;
    padding: 7px 13px 7px 17px;
    color: #333;
    font-size: 14px;
    letter-spacing: 4px;
    cursor: pointer;
  }
  .remarkText {
    font-size: 3px;
    line-height: 20px;
  }
}
</style>
