/**
 * @description: 请求结果
 */
export enum ResultEnum {
  SUCCESS = 0,
  ERROR = 1,
  TIMEOUT = 401,
  TYPE = 'success'
}

/**
 * @description: 请求方法
 */
export enum RequestEnum {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

/**
 * @description:  请求头
 */
export enum ContentTypeEnum {
  // json
  JSON = 'application/json;charset=UTF-8',
  // form-data qs
  FORM_URLENCODED = 'application/x-www-form-urlencoded;charset=UTF-8',
  // form-data  upload
  FORM_DATA = 'multipart/form-data;charset=UTF-8'
}

/**
 * @description:  用户身份
 */
export enum Role {
  // 未登录
  Visitor = -1,
  // 标注人员
  General = 0,
  // 观察人员
  Examine = 1,
  // 管理人员
  Professional = 2,
  // 超级管理人员
  SuperAdmin = 3
}

export enum MemberRole {
  // 管理员
  Admin = -1,
  // 创建者
  Creator = 0,
  // 编辑者
  Editor = 1,
  // 可查看
  Viewer = 2,
  // 禁用人
  Forbider = 3
}
