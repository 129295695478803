
import { defineComponent, onMounted } from 'vue';
export default defineComponent({
  name: 'EditMenu',
  props: {
    menu: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  setup(props, context) {
    onMounted(async () => {
      // 订阅数据变更
    });
    const checkMenu = (type: string) => {
      context.emit('checkMenu', type);
    };
    return {
      checkMenu
    };
  }
});
